<template>
  <div class="page6">
    <img class="page6-img" src="../../assets/pages/obj_06.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'page6',
}
</script>

<style scoped lang="scss">
.page6 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .page6-img {
    height: 100%;
  }
}
</style>